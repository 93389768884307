
import { useEffect, useState } from "react";
import { WrapQuest, QuestList, QuestItem, IconArrowDown, QuestTitle, QuestDesc, IconEdit, IconTrash, PaginationContainer, PrevIcon, PageNumbersContainer, PaginationButton, NextIcon } from "./QuestionList.styled";
import AddFaqItem from "components/modal/AddFaqItem/AddFaqItem";
import { useNavigate } from "react-router-dom";


const QuestionList = ({ questions, isModalOpen, setModalOpen, handleCloseModal, types, title, setTypes, setTitle, categoryId, currentPage }) => {
  const [expandedIds, setExpandedIds] = useState([]);
  const navigate = useNavigate();

const [selectedThemeItem, setSelectedThemeItem]=useState({});
  



  // Обробник кліку, що розгортає або згортає відповідь
  const toggleQuestion = (id) => {
    setExpandedIds(prevIds =>
      prevIds.includes(id)
        ? prevIds.filter(expandedId => expandedId !== id) // Якщо id вже є в масиві, видаляємо його (згортання)
        : [...prevIds, id] // Інакше додаємо id в масив (розгортання)
    );
  };

  const handleEventClick = (data) => {
   
    setSelectedThemeItem(data)
    setModalOpen(true);

  };
  const handleEditClick = (event, { id, title, description }) => {
    event.stopPropagation(); // Зупиняє поширення події
    setTypes('edit');
    setTitle('Редагувати питання');
    handleEventClick({ id, title, description });
  };
  
  const handleDeleteClick = (event, { id, title,  description }) => {
    event.stopPropagation(); // Зупиняє поширення події
    setTypes('del');
    setTitle('Видалити питання');
    handleEventClick({ id, title,  description });
  };
  const reverseQuestion = [...questions].reverse();
  const itemsPerPage = 10; // Кількість елементів на сторінці
  const totalPages = Math.ceil((questions?.length || 0) / itemsPerPage); // Розрахунок загальної кількості сторінок
  const displayedQuestions = questions?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      navigate(`?category=${categoryId}&page=${page}`);
    }
  };




  return (

    <QuestList>
      {
        displayedQuestions?.map(({ id, title, description, }) => 
        (<QuestItem key={id}>
          <WrapQuest onClick={() => toggleQuestion(id)}>
            <QuestTitle>{title}</QuestTitle>
            <IconArrowDown up={expandedIds.includes(id) ? "true" : "false"} />

          </WrapQuest>
          <QuestDesc expanded={expandedIds.includes(id)}>
            {description}
          </QuestDesc>
          <IconEdit onClick={(event) => handleEditClick(event, { id, title,  description })} />
          <IconTrash onClick={(event) => handleDeleteClick(event, { id, title,  description })} />
        </QuestItem>

        ))
      }
      {totalPages > 1 && (
        <PaginationContainer>
          <PrevIcon onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <PageNumbersContainer>
            {Array.from({ length: totalPages }, (_, index) => (
              <PaginationButton
                key={`page-${index + 1}`}
                onClick={() => handlePageChange(index + 1)}
                disabled={index + 1 === currentPage}
              >
                {index + 1}
              </PaginationButton>
            ))}
          </PageNumbersContainer>
          <NextIcon onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        </PaginationContainer>
      )}
 {isModalOpen && (
          <AddFaqItem categoryId ={categoryId }onClose={handleCloseModal} title={title} types={types} themeItem={selectedThemeItem} />
        )}
    </QuestList>


  );
};

export default QuestionList;
