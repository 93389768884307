import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const feedbacksApi = createApi({
  reducerPath: 'feedbacks',

  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1/feedbacks',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Feedbacks'],

  endpoints: builder => ({
    getClientFeedbacks: builder.query({
      query: ({ page = 1 } = {}) => {

        const params = {
          page, // додаємо параметр page до запиту

        };
        return {
          url: ``,
          method: 'GET',
          params,
        };
      },
      transformResponse: (response, meta) => {
        const pagination = {
          currentPage: meta.response.headers.get('x-pagination-current-page'),
          pageCount: meta.response.headers.get('x-pagination-page-count'),
          perPage: meta.response.headers.get('x-pagination-per-page'),
          totalCount: meta.response.headers.get('x-pagination-total-count'),
        };
        return { data: response, pagination };
      },
      providesTags: ['Feedbacks'],
    }),


    getFeedbacks: builder.query({
      query: ({ page = 1 } = {}) => {

        const params = {
          page, // додаємо параметр page до запиту

        };
        return {
          url: `/search`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (response, meta) => {
        const pagination = {
          currentPage: meta.response.headers.get('x-pagination-current-page'),
          pageCount: meta.response.headers.get('x-pagination-page-count'),
          perPage: meta.response.headers.get('x-pagination-per-page'),
          totalCount: meta.response.headers.get('x-pagination-total-count'),
        };
        return { data: response, pagination };
      },
      providesTags: ['Feedbacks'],
    }),
    returnUnreadFeedbacks: builder.query({
      query: () => ({
        url: `/unread`,
        method: 'GET',
      }),
      providesTags: ['Feedbacks'],
    }),


    acceptFeedbacks: builder.mutation({
      query: ({ id, comment }) => ({
        url: `/accept?id=${id}&comment=${comment}`,
        method: 'POST',
      }),
      invalidatesTags: ['Feedbacks'],
    }),

    declineFeedbacks: builder.mutation({
      query: ({ id, comment }) => ({
        url: `/decline?id=${id}&comment=${comment}`,
        method: 'POST',
      }),
      invalidatesTags: ['Feedbacks'],
    }),
  }),
});

export const {

  useGetClientFeedbacksQuery,
  useAcceptFeedbacksMutation,
  useDeclineFeedbacksMutation,
  useGetFeedbacksQuery,
  useReturnUnreadFeedbacksQuery
} = feedbacksApi;
